export const FILE_UPLOAD_STATE = {
  RUNNING: "running",
  PAUSED: "paused",
  SUCCESS: "success",
  CANCELED: "canceled",
  ERROR: "error",
}

export const UPLOAD_CONTROL = {
  PAUSE: { action: "pause", label: "一時停止" },
  CANCELE: { action: "cancel", label: "キャンセル" },
  RESUME: { action: "resume", label: "再開" },
}
