import React, { createContext, useContext, useEffect, useState } from "react"
// import { useFirebase } from "./FirebaseContext"
import { auth } from "../firebaseConfig"
import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  signInWithCredential,
  signInWithRedirect,
  signOut,
} from "firebase/auth"

const AuthContext = createContext()

export const useAuth = () => {
  return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(undefined)

  const handleSignInWithEmailAndPassword = async (email, password) => {
    try {
      signInWithEmailAndPassword(auth, email, password)
    } catch (error) {
      throw error
    }
  }

  const googleSignIn = async () => {
    console.log("googleSignIn")
    try {
      const result = await signInWithPopup(auth, new GoogleAuthProvider())
      console.log("googleSignIn Result : ", result)
      setUser(result.user)
    } catch (error) {
      throw error
    }
  }

  const googleSignOut = async () => {
    try {
      signOut(auth)
    } catch (error) {
      throw error
    }
  }

  const contextValue = {
    user,
    googleSignIn,
    googleSignOut,
    handleSignInWithEmailAndPassword,
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        console.log("onAuthStateChanged1", user)
        setUser(user)
      } else {
        console.log("onAuthStateChanged2", user)
      }
    })
    return () => unsubscribe()
  })

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}
