import React, { useState } from "react"
import {
  CButton,
  CCollapse,
  CListGroup,
  CListGroupItem,
  CFormCheck,
  CCard,
  CCardHeader,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CModalHeader,
  CModalBody,
  CModal,
  CBadge,
  CAlert,
} from "@coreui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as yup from "yup"
import { getIconForMimeType } from "./MIME"
import { useFirebase } from "../contexts/FirebaseContext"
import { useForm, Controller, FormProvider } from "react-hook-form"
const Detail = ({ details, displayItems, refreshDocuments, user }) => {
  const { removeMembers, removeFilesById, deleteFile } = useFirebase()
  const [stateModal, setStateModal] = useState({
    visible: false,
    message: "",
    color: "success",
  })
  const [activeKey, setActiveKey] = useState(1)
  const schema = yup.object().shape({})
  const defaultValues = { email: "", file: "" }
  const methods = useForm({
    mode: "onChange",
    shouldUnregister: false,
    shouldFocusError: true,
    defaultValues: defaultValues,
  })
  const [selectedFiles, setSelectedFiles] = useState([])
  const [selectedMembers, setSelectedMembers] = useState([])
  const getStatusIcon = (status) => {
    let component
    switch (status) {
      case "waiting":
        component = (
          <CBadge color="primary" shape="rounded-pill">
            Uploading
          </CBadge>
        )
        break
      case "success":
        component = (
          <CBadge color="success" shape="rounded-pill">
            Completed
          </CBadge>
        )
        break
      case "error":
        component = (
          <CBadge color="danger" shape="rounded-pill">
            Error
          </CBadge>
        )
        break
      default:
        break
    }
    return component
  }
  return (
    <>
      <CModal visible={stateModal.visible}>
        <CModalHeader>Information</CModalHeader>
        <CModalBody>{stateModal.message}</CModalBody>
      </CModal>
      <CCollapse visible={details.includes(displayItems.id)}>
        <CCard>
          <CCardHeader>
            <CNav variant="tabs" role="tablist">
              <CNavItem role="presentation">
                <CNavLink
                  active={activeKey === 1}
                  component="button"
                  role="tab"
                  aria-controls="files-tab-pane"
                  aria-selected={activeKey === 1}
                  onClick={() => setActiveKey(1)}
                >
                  <FontAwesomeIcon icon={["fad", "files"]} />
                  &nbsp;Files
                </CNavLink>
              </CNavItem>
              <CNavItem role="presentation">
                <CNavLink
                  active={activeKey === 2}
                  component="button"
                  role="tab"
                  aria-controls="members-tab-pane"
                  aria-selected={activeKey === 2}
                  onClick={() => setActiveKey(2)}
                >
                  &nbsp;
                  <FontAwesomeIcon icon={["fad", "users"]} />
                  Members
                </CNavLink>
              </CNavItem>
            </CNav>
          </CCardHeader>
          <CTabContent>
            <CTabPane
              role="tabpanel"
              aria-labelledby="files-tab-pane"
              visible={activeKey === 1}
              style={{ textAlign: "left" }}
            >
              {displayItems.files.length > 0 ? (
                <FormProvider {...methods}>
                  <CListGroup>
                    {displayItems.files.map((file, index) => {
                      console.log("File:", file)
                      return (
                        <CListGroupItem key={"detail_" + file.id}>
                          <Controller
                            control={methods.control}
                            name="file"
                            render={({
                              field: { onChange, onBlur, value, ref, name },
                            }) => (
                              <CFormCheck
                                hitArea="full"
                                label={
                                  <small>
                                    {getIconForMimeType(file.type)}&nbsp;
                                    {`${file.name}`}&nbsp;
                                    {getStatusIcon(file.status)}
                                  </small>
                                }
                                value={file.id}
                                id={`${displayItems.id}-${file.id}`}
                                name={name}
                                onChange={(e) => {
                                  onChange()
                                  if (e.target.checked) {
                                    setSelectedFiles((prev) => [
                                      ...prev,
                                      file.id,
                                    ])
                                  } else {
                                    setSelectedFiles(
                                      selectedFiles.filter(
                                        (item) => item !== file.id
                                      )
                                    )
                                  }
                                }}
                                checked={selectedFiles.find(
                                  (item) => item === file.id
                                )}
                              />
                            )}
                          />
                        </CListGroupItem>
                      )
                    })}
                  </CListGroup>
                </FormProvider>
              ) : (
                <CAlert color="danger" className="mt-3 mb-3">
                  <FontAwesomeIcon icon={["fad", "file-slash"]} />
                  &nbsp;No shared files.
                </CAlert>
              )}
              {selectedFiles.length > 0 && (
                <CButton
                  size="sm"
                  color="danger"
                  className="mt-5 mb-1"
                  disabled={selectedFiles.length === 0}
                  onClick={async () => {
                    const result = await removeFilesById(
                      displayItems.id,
                      selectedFiles
                    )
                    if (result.success)
                      refreshDocuments(
                        process.env.REACT_APP_DEFAULT_COLLECTION,
                        user?.uid
                      )
                    setStateModal((prev) => ({
                      ...prev,
                      visible: true,
                      message: result.message,
                      color: result.success ? "success" : "danger",
                    }))
                    setSelectedFiles([])
                  }}
                >
                  ファイル削除
                </CButton>
              )}
            </CTabPane>
            <CTabPane
              role="tabpanel"
              aria-labelledby="members-tab-pane"
              visible={activeKey === 2}
              style={{ textAlign: "left" }}
            >
              {displayItems.sharedEmails.length > 0 ? (
                <FormProvider method>
                  <CListGroup>
                    {displayItems.sharedEmails.map((email, index) => {
                      return (
                        <CListGroupItem key={email.emailAddress}>
                          <Controller
                            control={methods.control}
                            name="email"
                            render={({
                              field: { onChange, onBlur, value, ref, name },
                            }) => (
                              <CFormCheck
                                hitArea="full"
                                label={
                                  <small>
                                    {email.emailAddress}
                                    {email.verification}
                                    {email.verification}
                                    {email.download}
                                  </small>
                                }
                                value={value}
                                id={`${displayItems.id}-${email}`}
                                name={name}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSelectedMembers((prev) => [
                                      ...prev,
                                      email,
                                    ])
                                  } else {
                                    setSelectedMembers(
                                      selectedMembers.filter(
                                        (item) => item !== email
                                      )
                                    )
                                  }
                                  onChange()
                                }}
                                checked={selectedMembers.find(
                                  (item) => item === email
                                )}
                              />
                            )}
                          />
                        </CListGroupItem>
                      )
                    })}
                  </CListGroup>
                </FormProvider>
              ) : (
                <CAlert color="danger" className="mt-3 mb-3">
                  <FontAwesomeIcon icon={["fad", "user-large-slash"]} />
                  &nbsp;There are no members
                </CAlert>
              )}
              {selectedMembers.length > 0 && (
                <CButton
                  size="sm"
                  color="danger"
                  className="mt-5 mb-1"
                  disabled={selectedMembers.length === 0}
                  onClick={async () => {
                    const result = await removeMembers(
                      displayItems.id,
                      selectedMembers
                    )
                    if (result.success)
                      refreshDocuments(
                        process.env.REACT_APP_DEFAULT_COLLECTION,
                        user?.uid
                      )
                    setStateModal((prev) => ({
                      ...prev,
                      visible: true,
                      message: result.message,
                      color: result.success ? "success" : "danger",
                    }))
                    setSelectedMembers([])
                  }}
                >
                  メンバー削除
                </CButton>
              )}
            </CTabPane>
          </CTabContent>
        </CCard>
      </CCollapse>
    </>
  )
}
export default Detail
