import React, { useEffect, useState } from "react"
import { CModalBody, CModalHeader, CSmartTable } from "@coreui/react-pro"
import {
  CAlert,
  CButton,
  CContainer,
  CRow,
  CCol,
  CModal,
  CModalTitle,
  CModalFooter,
  CListGroup,
  CListGroupItem,
  CBadge,
} from "@coreui/react"
import Detail from "./Detail"
import { useFirebase } from "../contexts/FirebaseContext"
import { useAuth } from "../contexts/AuthContext"
import { format } from "date-fns"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
const FileList = () => {
  console.log("FileList")
  const {
    fetchCollection,
    deleteDocumentsByIds,
    docCollection,
    deleteFolders,
  } = useFirebase()
  const { user } = useAuth()

  const [documents, setDocuments] = useState(null)
  const [alertState, setAlertState] = useState({
    visible: false,
    message: "",
    color: "success",
  })
  const [loading, setLoading] = useState(false)
  const [details, setDetails] = useState([])
  const [selectedDocs, setSelectedDocs] = useState([])
  const [visibleRemoveConfirm, setVisibleRemoveConfirm] = useState(false)

  useEffect(() => {
    setLoading(true)
    let docs = []
    docCollection.forEach((doc) => {
      docs.push({
        id: doc.id,
        created_at: doc.created_at.seconds,
        expiryDate: doc.expiryDate.seconds,
        files: doc.files,
        ownerId: doc.ownerId,
        sharedEmails: doc.sharedEmails,
        subject: doc.subject,
        _selected: false,
        _props: { align: "middle" },
      })
    })
    setDocuments(docs)
    setLoading(false)
  }, [docCollection])

  useEffect(() => {
    console.log("useEffect", user.uid)
    if (user.uid) {
      fetchCollection(process.env.REACT_APP_DEFAULT_COLLECTION, user.uid)
    }
  }, [user])

  const columns = [
    {
      key: "created_at",
      label: "Created",
      _props: { align: "center" },
    },
    {
      key: "subject",
      label: "Subject",
      _props: { align: "center" },
    },
    {
      key: "files",
      label: "Files",
      _props: { align: "center" },
    },
    {
      key: "members",
      label: "Members",
      _props: { align: "center" },
    },
    {
      key: "expiryDate",
      label: "Expiry",
      _props: { align: "center" },
    },
    {
      key: "link",
      label: "Link",
      _props: { align: "center" },
    },
    {
      key: "show_details",
      label: "共有",
      _props: { align: "center" },
    },
  ]

  const toggleDetails = (index) => {
    const position = details.indexOf(index)
    let newDetails = details.slice()
    if (position !== -1) {
      newDetails.splice(position, 1)
    } else {
      newDetails = [...details, index]
    }
    setDetails(newDetails)
  }
  const copyToClipboard = async (textToCopy) => {
    try {
      await navigator.clipboard.writeText(textToCopy)
    } catch (err) {}
  }
  return (
    <>
      <CContainer>
        {user && documents && (
          <>
            <CRow>
              <CCol>
                <div className="display-6 mt-1 mb-1">Share Objects</div>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CAlert
                  color={alertState.color}
                  visible={alertState.visible}
                  onClose={() => setAlertState({ visible: false })}
                  dismissible
                >
                  <>{alertState.message}</>
                </CAlert>
              </CCol>
            </CRow>
            <CRow>
              <CCol className="text-end">
                <CButton
                  size="sm"
                  color="danger"
                  disabled={selectedDocs.length === 0}
                  onClick={() => setVisibleRemoveConfirm(true)}
                >
                  {selectedDocs.length > 0
                    ? `Delete ${selectedDocs.length} Shares`
                    : "Delete"}
                </CButton>
              </CCol>
            </CRow>
            <CRow>
              <CSmartTable
                columnSorter
                selectable
                onSelectAll={() => {
                  console.log("onSelectAll")
                }}
                onSelectedItemsChange={(items) => {
                  console.log("onSelectedItemsChange : ", items)
                  setSelectedDocs(
                    items.map((item) => ({ ...item, _selected: true }))
                  )
                }}
                columns={columns}
                items={documents}
                itemsPerPageSelect
                loading={loading}
                clickableRows
                pagination
                tableProps={{
                  hover: true,
                  responsive: true,
                }}
                tableBodyProps={{ align: "center" }}
                noItemsLabel={<small>File not found.</small>}
                scopedColumns={{
                  subject: (item) => (
                    <td>
                      <small>{item.subject}</small>
                    </td>
                  ),
                  files: (item) => (
                    <td>
                      {item.files.length > 0 ? (
                        `${item.files.length}`
                      ) : (
                        <small>File not found.</small>
                      )}
                    </td>
                  ),
                  members: (item) => (
                    <td>
                      {item.sharedEmails.length > 0 ? (
                        `${item.sharedEmails.length}`
                      ) : (
                        <small>No recipients have been set for sharing.</small>
                      )}
                    </td>
                  ),
                  expiryDate: (item) => (
                    <td>
                      <small>
                        {new Date() > new Date(item.expiryDate * 1000) && (
                          <FontAwesomeIcon
                            icon={("fad", "circle-exclamation")}
                            color={"red"}
                            className="fa-beat-fade"
                          />
                        )}
                        {format(
                          new Date(item.expiryDate * 1000),
                          "yyyy-MM-dd HH:mm:ss"
                        )}
                      </small>
                    </td>
                  ),
                  created_at: (item) => (
                    <td>
                      <small>
                        {format(
                          new Date(item.created_at * 1000),
                          "yyyy-MM-dd HH:mm:ss"
                        )}
                      </small>
                    </td>
                  ),
                  link: (item) => (
                    <td>
                      <CButton
                        size="sm"
                        color="primary"
                        shape="rounded-pill"
                        onClick={() => {
                          copyToClipboard(
                            `${process.env.REACT_APP_DOWNLOAD_BASE_URL}${item.id}`
                          )
                        }}
                      >
                        <small>
                          <FontAwesomeIcon icon={["fad", "link"]} />
                          Copy Link
                        </small>
                      </CButton>
                    </td>
                  ),
                  show_details: (item) => {
                    return (
                      <td className="py-2">
                        <CButton
                          size="sm"
                          color="primary"
                          variant="outline"
                          shape="square"
                          onClick={() => {
                            toggleDetails(item.id)
                          }}
                        >
                          {details.includes(item.id) ? (
                            <FontAwesomeIcon icon={["fad", "caret-up"]} />
                          ) : (
                            <FontAwesomeIcon icon={["fad", "caret-down"]} />
                          )}
                        </CButton>
                      </td>
                    )
                  },
                  details: (item) => {
                    return (
                      <Detail
                        displayItems={item}
                        details={details}
                        refreshDocuments={fetchCollection}
                        user={user}
                      />
                    )
                  },
                }}
              />
            </CRow>
          </>
        )}
        {!documents && <p>No documents are shared.</p>}
      </CContainer>
      <CModal
        visible={visibleRemoveConfirm}
        onClose={() => setVisibleRemoveConfirm(false)}
      >
        <CModalHeader>
          <CModalTitle>{`You are about to delete ${selectedDocs.length} file(s).`}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CListGroup>
            {selectedDocs.map((doc) => {
              return (
                <CListGroupItem key={`confirm${doc.id}`}>
                  <small>{doc.subject}</small>
                </CListGroupItem>
              )
            })}
          </CListGroup>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="secondary"
            onClick={() => setVisibleRemoveConfirm(false)}
            size="sm"
          >
            Cancel
          </CButton>
          <CButton
            size="sm"
            color="danger"
            onClick={async () => {
              const resultDb = await deleteDocumentsByIds(selectedDocs)
              // const resultsStorage = await deleteFolders(selectedDocs)
              // const allStorageSuccess = resultsStorage.every(
              //   (result) => result.success
              // )
              if (resultDb.success) {
                setAlertState({
                  visible: true,
                  message: "Data deleted successfully.",
                  color: "success",
                })
                fetchCollection(
                  process.env.REACT_APP_DEFAULT_COLLECTION,
                  user.uid
                )
              } else {
                // const storageMessages = resultsStorage
                //   .map((result) => `${result.docId}: ${result.message}`)
                //   .join(", ")
                setAlertState({
                  visible: true,
                  message: `Error in Database: ${resultDb.message}`,
                  color: "danger",
                })
              }

              setVisibleRemoveConfirm(false)
            }}
          >
            {`${selectedDocs.length}Shares`}Delete
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}
export default FileList
