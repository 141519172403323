import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import { addDoc, collection } from "firebase/firestore"
import { storage, firestore } from "./firebaseConfig"
export async function uploadFile(
  files,
  params,
  setUploadProgress,
  setUploadTasks,
  updateDocStatus
) {
  try {
    // firestore
    const docRef = await addDoc(
      collection(firestore, process.env.REACT_APP_DEFAULT_COLLECTION),
      params
    )

    // firestrage
    const uploadPromises = files.map(async (obj) => {
      const fileRef = ref(storage, `files/${docRef.id}/${obj.file.name}`)
      const uploadTask = uploadBytesResumable(fileRef, obj.file, {
        id: obj.id,
        file: obj.file,
        contentDisposition: `attachment; filename="${obj.file.name}"`,
      })
      return new Promise((innerResolve, innerReject) => {
        setUploadTasks((prev) => [...prev, uploadTask])
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            switch (snapshot.state) {
              case "canceled":
                updateDocStatus(docRef.id, obj.file.name, "canceled")
                break
              case "error":
                updateDocStatus(docRef.id, obj.file.name, "error")
                break
              default:
                break
            }
            setUploadProgress((prevProgress) => ({
              ...prevProgress,
              [obj.id]: {
                bytesTransferred: snapshot.bytesTransferred,
                totalBytes: snapshot.totalBytes,
              },
            }))
          },
          (error) => {
            console.error("Error", error)
            updateDocStatus(docRef.id, obj.file.name, "error")
            innerReject(error)
          },
          async () => {
            console.log("comp : ", obj.file.name)
            await updateDocStatus(docRef.id, obj.file.name, "success").then(
              (res) => {
                console.log("RES : ", res)
                const getUrl = async () => {
                  const url = await getDownloadURL(fileRef)
                  innerResolve({
                    file: obj.file,
                    url,
                    docId: docRef.id,
                    status: "success",
                  })
                }
                getUrl()
              }
            )
          }
        )
      })
    })
    return await Promise.all(uploadPromises)
  } catch (error) {
    throw error
  }
}
