import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
export const getIconForMimeType = (mimeType) => {
  let component
  switch (mimeType) {
    case "text/html":
      component = <FontAwesomeIcon icon={["fad", "file-code"]} />
      break
    case "text/plain":
      component = <FontAwesomeIcon icon={["fad", "file"]} />
      break
    case "text/csv":
      component = <FontAwesomeIcon icon={["fad", "file-csv"]} />
      break
    case "text/javascript":
      component = <FontAwesomeIcon icon="fa-brands fa-js" />
      break
    case "image/jpeg":
    case "image/png":
    case "image/gif":
    case "image/bmp":
    case "image/svg+xml":
    case "image/webp":
      component = <FontAwesomeIcon icon={["fad", "file-image"]} />
      break
    case "audio/mpeg":
    case "audio/ogg":
    case "audio/wav":
    case "audio/webm":
    case "audio/aac":
    case "audio/midi":
      component = <FontAwesomeIcon icon={["fad", "file-audio"]} />
      break
    case "video/mp4":
    case "video/quicktime":
    case "video/webm":
    case "video/x-msvideo":
    case "video/mpeg":
    case "video/ogg":
      component = <FontAwesomeIcon icon={["fad", "file-video"]} />
      break
    case "application/octet-stream":
    case "application/pkcs12":
    case "application/xhtml+xml":
    case "application/xml":
    case "application/json":
    case "application/ld+json":
      component = <FontAwesomeIcon icon={["fad", "file-powerpoint"]} />
      break
    case "application/vnd.ms-excel":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      component = <FontAwesomeIcon icon={["fad", "file-excel"]} />
      break
    case "application/vnd.mspowerpoint":
      component = <FontAwesomeIcon icon={["fad", "file-powerpoint"]} />
      break
    case "application/msword":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      component = <FontAwesomeIcon icon={["fad", "file-word"]} />
      break
    case "application/pdf":
      component = <FontAwesomeIcon icon={["fad", "file-pdf"]} />
      break
    default:
      component = <FontAwesomeIcon icon={["fad", "file"]} />
      break
  }
  return component
}

// MIMEタイプの配列
const mimeTypes = [
  "text/plain",
  "text/html",
  "text/css",
  "text/javascript",
  "text/xml",
  "text/csv",
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/bmp",
  "image/svg+xml",
  "image/webp",
  "audio/mpeg",
  "audio/ogg",
  "audio/wav",
  "audio/webm",
  "audio/aac",
  "audio/midi",
  "video/mp4",
  "video/quicktime",
  "video/webm",
  "video/x-msvideo",
  "video/mpeg",
  "video/ogg",
  "application/octet-stream",
  "application/pkcs12",
  "application/vnd.mspowerpoint",
  "application/xhtml+xml",
  "application/xml",
  "application/pdf",
  "application/msword",
  "application/json",
  "application/ld+json",
  "multipart/form-data",
  "multipart/byteranges",
  "multipart/alternative",
  "message/rfc822",
]
