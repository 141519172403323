import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { auth, firestore } from "../firebaseConfig"
import {
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
} from "firebase/auth"
import { doc, setDoc, getDoc } from "firebase/firestore"

const SignUpPage = () => {
  const navigate = useNavigate()
  const [error, setError] = useState("")

  const handleSignUpWithEmail = async (email, password) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      )
      await handleSignUp(userCredential.user)
    } catch (err) {
      setError("このメールアドレスは既に登録されています。")
      navigate("/login")
    }
  }

  const handleSignUpWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider()
      const userCredential = await signInWithPopup(auth, provider)
      await handleSignUp(userCredential.user)
    } catch (err) {
      setError("このGoogleアカウントは既に登録されています。")
      navigate("/login")
    }
  }

  const handleSignUpWithMicrosoft = async () => {
    try {
      const provider = new OAuthProvider("microsoft.com")
      const userCredential = await signInWithPopup(auth, provider)
      await handleSignUp(userCredential.user)
    } catch (err) {
      setError("このMicrosoftアカウントは既に登録されています。")
      navigate("/login")
    }
  }

  const handleSignUp = async (user) => {
    try {
      const userRef = doc(firestore, "users", user.uid)
      const userSnapshot = await getDoc(userRef)

      if (!userSnapshot.exists()) {
        await setDoc(userRef, {
          uid: user.uid,
          email: user.email,
          // 他のユーザー情報もここに追加
        })
      }
      navigate("/login") // サインアップ後にログインページへリダイレクト
    } catch (error) {
      console.error("Error during sign-up:", error)
      setError("サインアップ中にエラーが発生しました。")
    }
  }

  return (
    <div>
      <h1>SignUp Page</h1>
      <button onClick={handleSignUpWithEmail}>SignUp with Email</button>
      <button onClick={handleSignUpWithGoogle}>SignUp with Google</button>
      <button onClick={handleSignUpWithMicrosoft}>SignUp with Microsoft</button>
      {error && <p>{error}</p>}
    </div>
  )
}

export default SignUpPage
