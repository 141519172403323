import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../contexts/AuthContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  CButton,
  CForm,
  CFormInput,
  CContainer,
  CRow,
  CCol,
  CAlert,
} from "@coreui/react"
const LoginPage = () => {
  const { handleSignInWithEmailAndPassword, googleSignIn } = useAuth()

  const navigate = useNavigate()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")

  /**
   * メールアドレスログイン
   */
  const handleLoginWithEmail = async () => {
    try {
      handleSignInWithEmailAndPassword(email, password)
      navigate("/admin")
    } catch (err) {
      console.error("Error : ", err)
      setError("メールアドレスまたはパスワードが正しくありません。")
    }
  }

  /**
   * Googleログイン
   */
  const handleSignInWithGoogle = async () => {
    try {
      await googleSignIn()
      navigate("/admin")
    } catch (err) {
      setError("Googleでのログインに失敗しました。")
    }
  }
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CContainer>
        <CRow className="justify-content-md-center">
          <CCol xxl={6} xl={8} md={10} sm={12} className="align-self-center">
            <h1>Login</h1>
          </CCol>
        </CRow>
        <CRow className="justify-content-md-center">
          <CCol xxl={6} xl={6} md={6} sm={6} className="align-self-center">
            <CForm>
              <CFormInput
                size="sm"
                type="email"
                placeholder="name@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                floatingLabel="Email address"
                floatingClassName="mb-3"
              />
              <CFormInput
                size="sm"
                type="password"
                placeholder="name@example.com"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                floatingLabel="Password"
                floatingClassName="mb-3"
              />
              <div className="text-center mt-1">
                <CButton>
                  <FontAwesomeIcon icon={("fad", "envelope")} />
                  &nbsp;Login with Email
                </CButton>
              </div>
            </CForm>
            <hr />
            <div className="text-center mt-5">
              <CButton onClick={handleSignInWithGoogle} color="primary">
                <FontAwesomeIcon icon="fa-brands fa-google" />
                &nbsp;Login with Google
              </CButton>
            </div>
            {error && (
              <CAlert color="danger" className="mt-3">
                <FontAwesomeIcon icon={("fad", "circle-exclamation")} />
                &nbsp;{error}
              </CAlert>
            )}
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default LoginPage
