import React, { useState, useCallback } from "react"
import { useDropzone } from "react-dropzone"
import { useForm, Controller, FormProvider } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { uploadFile } from "../services/uploadFile"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  CAlert,
  CButton,
  CForm,
  CFormInput,
  CInputGroup,
  CFormLabel,
  CBadge,
  CContainer,
  CRow,
  CCol,
} from "@coreui/react"
import UploadConfirmModal from "./UploadConfirmModal"
import { CMultiSelect } from "@coreui/react-pro"
import { formatDistanceToNow, addWeeks, format } from "date-fns"
import { ja } from "date-fns/locale"
import { useAuth } from "../contexts/AuthContext"
import { useFirebase } from "../contexts/FirebaseContext"
import { v4 as uuidv4 } from "uuid"
import Timer from "./Timer"
const Uploader = () => {
  const { user } = useAuth()
  const {
    serverTimestamp,
    updateDocStatus,
    sendEmailNotificationOnCall,
    fetchCollection,
  } = useFirebase()
  const [uploadProgress, setUploadProgress] = useState([])
  const [isUploading, setIsUploading] = useState(false)
  const [email, setEmail] = useState("")
  const [subject, setSubject] = useState("共有")
  const [sharedEmails, setSharedEmails] = useState([])
  const [userAddEmail, setUserAddEmail] = useState(false)
  const [visibleModal, setVisibleModal] = useState(false)
  const [files, setFiles] = useState([])
  const [isCancelOnChange, setIsCancelOnChange] = useState(false)
  const [startTime, setStartTime] = useState(null)
  const [uploadControl, setUploadControl] = useState(null)
  const [uploadStatus, setUploadStatus] = useState()
  const [uploadTasks, setUploadTasks] = useState([])
  const [alertParams, setAlertParams] = useState({
    color: "info",
    visible: false,
    message: "",
  })
  const [expiryDate, setExpiryDate] = useState(
    addWeeks(new Date(), process.env.REACT_APP_EXPIRATION_DATE)
  )

  const onDrop = useCallback((acceptedFiles) => {
    setStartTime(null)
    setAlertParams((prevParams) => ({
      ...prevParams,
      visible: false,
    }))
    if (acceptedFiles.length > 0) {
      setIsCancelOnChange(true)
      setFiles((prevFiles) => [
        ...prevFiles,
        ...acceptedFiles.map((file) => ({
          id: uuidv4(),
          file,
        })),
      ])
    }
  }, [])

  const handleUpload = async () => {
    setVisibleModal(false)
    setIsUploading(true)
    const startTime = new Date() // アップロード開始時間を変数に保存
    setStartTime(startTime)
    setUploadTasks([])

    //Firestore用パラメータ
    const params = {
      ownerId: user.uid,
      subject: subject,
      sharedEmails: sharedEmails.map((email) => ({
        emailAddress: email,
        download: "",
        verification: "",
      })),
      expiryDate: new Date(expiryDate),
      created_at: serverTimestamp(),
      files: files.map((fileObj) => {
        return {
          name: fileObj.file.name,
          type: fileObj.file.type,
          id: fileObj.id,
          status: "waiting",
        }
      }),
    }
    await uploadFile(
      files,
      params,
      setUploadProgress,
      setUploadTasks,
      updateDocStatus
    )
      .then((res) => {
        sendEmailNotificationOnCall(res[0].docId)
        setAlertParams({
          color: "success",
          visible: true,
          message: (
            <span>
              <FontAwesomeIcon icon={["fad", "circle-check"]} />
              ファイルのアップロードが正常に完了しました（所要時間:約
              {formatDistanceToNow(startTime, {
                includeSeconds: true,
                locale: ja,
              })}
              ）
            </span>
          ),
        })
        methods.reset()
        setFiles([])
      })
      .catch((error) => {
        setAlertParams({
          color: "warning",
          visible: true,
          message: error.message,
        })
      })
      .finally(() => {
        methods.reset()
        setIsUploading(false)
        setEmail(null)
        fetchCollection(process.env.REACT_APP_DEFAULT_COLLECTION, user?.uid)
      })
  }

  const handleConfirm = async () => {
    setVisibleModal(true)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
  })

  const addSharedEmail = () => {
    if (email && !sharedEmails.includes(email)) {
      setUserAddEmail(true)
      setSharedEmails((prevEmails) => [...prevEmails, email])
      // setEmail("")
      methods.reset({ email: "" })
    }
  }

  const schema = yup.object().shape({
    email: yup
      .string()
      .required("共有者のメールアドレスは必ず指定してください")
      .email("メールアドレスの形式に誤りがあります"),
  })

  const defaultValues = { email: "" }
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldUnregister: false,
    shouldFocusError: true,
    defaultValues: defaultValues,
  })

  const onSubmit = (data) => {
    console.log("onSubmit", data)
  }
  return (
    <>
      <CContainer>
        <CRow>
          <CCol>
            <div className="display-6 mt-1 mb-1">アップローダー</div>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CAlert
              color={alertParams.color}
              visible={alertParams.visible}
              dismissible
              onClose={() => {
                setAlertParams((prevParams) => ({
                  ...prevParams,
                  visible: false,
                }))
                // setStartTime(null)
              }}
            >
              {alertParams.message}
            </CAlert>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <FormProvider {...methods}>
              <CForm onSubmit={methods.handleSubmit(onSubmit)}>
                {!isUploading && (
                  <div {...getRootProps()} style={styles.dropzone}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>ファイルをここにドロップしてください...</p>
                    ) : (
                      <p>
                        ファイルをドロップするか、クリックしてファイルを選択してください。
                      </p>
                    )}
                  </div>
                )}
                {isUploading && (
                  <Timer
                    startTime={startTime}
                    // progress={uploadProgress}
                    uploadTasks={uploadTasks}
                    uploadStatus={uploadStatus}
                    setUploadControl={setUploadControl}
                  />
                )}
                {!isUploading && (
                  <>
                    {files && files.length > 0 && (
                      <Controller
                        control={methods.control}
                        name="ReactDatepicker"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <CMultiSelect
                            options={
                              files.length > 0 &&
                              files.map((obj) => {
                                if (obj !== undefined) {
                                  return {
                                    value: obj?.id,
                                    text: obj?.file?.name,
                                    selected: true,
                                  }
                                } else return false
                              })
                            }
                            selectAllLabel=""
                            label={
                              <>
                                <FontAwesomeIcon icon={["fad", "files"]} />
                                ファイル一覧
                              </>
                            }
                            onChange={(selected) => {
                              if (!isCancelOnChange) {
                                setFiles(
                                  selected.map((item) => {
                                    return files.find((file) => {
                                      return file.id === item.value
                                    })
                                  })
                                )
                              } else {
                                setIsCancelOnChange(false)
                              }
                            }}
                          />
                        )}
                      />
                    )}
                  </>
                )}
                {!isUploading && (
                  <>
                    <CFormLabel htmlFor="subject" className="mt-3">
                      <FontAwesomeIcon icon={["fad", "subtitles"]} />
                      &nbsp;表題
                    </CFormLabel>
                    <CFormInput
                      id="subject"
                      type="text"
                      value={subject}
                      placeholder="共有"
                      onChange={(e) => setSubject(e.target.value)}
                    />
                    <CFormLabel htmlFor="expireyDate" className="mt-3">
                      <FontAwesomeIcon icon={["fad", "calendar"]} />
                      &nbsp;有効期限
                    </CFormLabel>
                    <Controller
                      control={methods.control}
                      name="ReactDatepicker"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <CFormInput
                          id="expireyDate"
                          type="datetime-local"
                          value={format(expiryDate, "yyyy-MM-dd'T'HH:mm:ss")}
                          onChange={(e) =>
                            setExpiryDate(new Date(e.target.value))
                          }
                        />
                      )}
                    />
                    <CFormLabel htmlFor="email" className="mt-3">
                      <FontAwesomeIcon icon={["fad", "user"]} />
                      &nbsp;共有
                    </CFormLabel>
                    <CInputGroup className="mb-3">
                      <Controller
                        control={methods.control}
                        name="email"
                        render={({ field: { onChange, name, value } }) => (
                          <CFormInput
                            size="sm"
                            id={name}
                            name={name}
                            placeholder="共有相手のメールアドレス"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            value={value}
                            onChange={(e) => {
                              onChange(e)
                              setEmail(e.target.value)
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault() // フォームのデフォルトの送信を防ぐ
                                addSharedEmail() // 追加関数を呼び出す
                              }
                            }}
                            // onChange={onChange} // send value to hook form
                          />
                        )}
                      />
                      <CButton
                        size="sm"
                        color="primary"
                        id="Cbutton-addon2"
                        onClick={addSharedEmail}
                      >
                        追加
                      </CButton>
                    </CInputGroup>
                    <ErrorMessage
                      name="email"
                      render={({ message }) => (
                        <CBadge
                          color="danger"
                          className="mt-1"
                          shape="rounded-pill"
                        >
                          <FontAwesomeIcon icon={["fad", "warning"]} />
                          &nbsp;{message}
                        </CBadge>
                      )}
                    />

                    {sharedEmails && sharedEmails.length > 0 && (
                      <>
                        <Controller
                          control={methods.control}
                          name="ReactDatepicker"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <CMultiSelect
                              options={sharedEmails.map((email) => ({
                                value: email,
                                text: email,
                                selected: true,
                              }))}
                              label={
                                <>
                                  <FontAwesomeIcon icon={["fad", "users"]} />
                                  ユーザー一覧
                                </>
                              }
                              size="sm"
                              onChange={(selected) => {
                                if (userAddEmail) {
                                  setUserAddEmail(false)
                                } else {
                                  setSharedEmails(
                                    selected.map((email) => {
                                      return email.value
                                    })
                                  )
                                }
                              }}
                            />
                          )}
                        />
                      </>
                    )}
                    {sharedEmails &&
                      sharedEmails.length > 0 &&
                      files.length > 0 && (
                        <div className="d-grid gap-2 col-4 mx-auto">
                          <CButton
                            size="lg"
                            onClick={handleConfirm}
                            color="primary"
                            className="mt-5"
                          >
                            <FontAwesomeIcon
                              icon={["fad", "share-from-square"]}
                            />
                            共有
                          </CButton>
                        </div>
                      )}
                  </>
                )}
              </CForm>
            </FormProvider>
          </CCol>
        </CRow>
      </CContainer>
      <UploadConfirmModal
        visibleModal={visibleModal}
        setVisibleModal={setVisibleModal}
        files={files}
        sharedEmails={sharedEmails}
        handleUpload={handleUpload}
      />
    </>
  )
}

const styles = {
  dropzone: {
    border: "2px dashed #cccccc",
    borderRadius: "4px",
    padding: "20px",
    textAlign: "center",
    cursor: "pointer",
  },
}
export default Uploader
