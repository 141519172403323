import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import AdminPage from "./Pages/AdminPage"
import { useAuth } from "./contexts/AuthContext"
const PrivateRoute = () => {
  const { currentUser } = useAuth()
  const navigate = useNavigate()
  useEffect(() => {
    if (!currentUser) {
      navigate("/login")
    }
  }, [currentUser, navigate])

  return currentUser ? <AdminPage /> : null
}
export default PrivateRoute
