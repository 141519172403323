import React, { useState } from "react"
import {
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CModalTitle,
} from "@coreui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PropTypes from "prop-types"
const UploadConfirmModal = (props) => {
  return (
    <CModal
      visible={props.visibleModal}
      alignment="center"
      onClose={() => props.setVisibleModal(false)}
    >
      <CModalHeader closeButton={false}>
        <CModalTitle>
          <FontAwesomeIcon
            icon={["fad", "circle-exclamation"]}
            style={{ color: "#ff0000" }}
          />
          &nbsp;ファイル共有の確認
        </CModalTitle>
      </CModalHeader>
      <CModalBody>
        <h6>
          <FontAwesomeIcon icon={["fad", "files"]} />
          &nbsp;{`以下の${props.files?.length}ファイルを共有します`}
        </h6>
        <ol>
          {props.files &&
            props.files.map((obj, index) => {
              return (
                <li key={index}>
                  <small>{obj?.file?.name}</small>
                </li>
              )
            })}
        </ol>
        {props.sharedEmails && props.sharedEmails.length > 0 && (
          <>
            <h6>
              <FontAwesomeIcon icon={["fad", "users"]} />
              &nbsp;{`共有先(${props.sharedEmails?.length}人)`}
            </h6>
            <ul>
              {props.sharedEmails.map((email, index) => {
                return (
                  <li key={index}>
                    <small>{email}</small>
                  </li>
                )
              })}
            </ul>
          </>
        )}
      </CModalBody>
      <CModalFooter>
        <CButton
          color="secondary"
          size="sm"
          onClick={() => props.setVisibleModal(false)}
        >
          Cancel
        </CButton>
        <CButton color="danger" size="sm" onClick={props.handleUpload}>
          <FontAwesomeIcon icon={["fad", "share-from-square"]} />
          &nbsp;共有実行
        </CButton>
      </CModalFooter>
    </CModal>
  )
}
export default UploadConfirmModal

UploadConfirmModal.propTypes = {
  visibleModal: PropTypes.bool,
  setVisibleModal: PropTypes.func,
  files: PropTypes.array,
  sharedEmails: PropTypes.array,
}
UploadConfirmModal.defaultProps = {
  visibleModal: false,
  files: [],
  sharedEmails: [],
}
