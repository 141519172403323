import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useAuth } from "../contexts/AuthContext"
import { CButton, CModal, CModalHeader, CModalBody } from "@coreui/react"
const LogoutButton = () => {
  const { user, googleSignOut } = useAuth()
  const navigate = useNavigate()
  const handleLogout = async () => {
    try {
      await googleSignOut()
      navigate("/login")
    } catch (error) {
      console.error("An error occurred during logout.:", error)
    }
  }

  return (
    <>
      {user?.uid && (
        <>
          <CButton size="sm" color="light" onClick={handleLogout}>
            <FontAwesomeIcon icon={["fad", "right-from-bracket"]} />
            &nbsp;Logout
          </CButton>
        </>
      )}
    </>
  )
}

export default LogoutButton
