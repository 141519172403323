import React, { useState } from "react"
import {
  CSpinner,
  CNav,
  CNavItem,
  CNavLink,
  CTabPane,
  CTabContent,
  CContainer,
  CRow,
  CCol,
} from "@coreui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import FileList from "../components/FileList"
import Uploader from "../components/Uploader"
import { useAuth } from "../contexts/AuthContext"
const AdminPage = () => {
  const [activeKey, setActiveKey] = useState(1)
  const { user } = useAuth()
  console.log("AdminPage", user)
  return (
    <>
      {user && user?.uid ? (
        <CContainer>
          <CRow>
            <CCol>
              <CNav variant="tabs" role="tablist">
                <CNavItem role="presentation">
                  <CNavLink
                    active={activeKey === 1}
                    component="button"
                    role="tab"
                    aria-controls="upload"
                    aria-selected={activeKey === 1}
                    onClick={() => setActiveKey(1)}
                  >
                    <FontAwesomeIcon icon={["fad", "cloud-arrow-up"]} />
                    &nbsp;Upload
                  </CNavLink>
                </CNavItem>
                <CNavItem role="presentation">
                  <CNavLink
                    active={activeKey === 2}
                    component="button"
                    role="tab"
                    aria-controls="list"
                    aria-selected={activeKey === 2}
                    onClick={() => setActiveKey(2)}
                  >
                    <FontAwesomeIcon icon={["fad", "share-nodes"]} />
                    &nbsp;Shares
                  </CNavLink>
                </CNavItem>
              </CNav>
              <CTabContent>
                <CTabPane
                  role="tabpanel"
                  aria-labelledby="upload"
                  visible={activeKey === 1}
                >
                  <Uploader />
                </CTabPane>
                <CTabPane
                  role="tabpanel"
                  aria-labelledby="list"
                  visible={activeKey === 2}
                >
                  <FileList />
                </CTabPane>
              </CTabContent>
            </CCol>
          </CRow>
        </CContainer>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CSpinner color="primary" />
        </div>
      )}
    </>
  )
}
export default AdminPage
