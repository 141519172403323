import React from "react"
import { CContainer, CRow, CCol } from "@coreui/react"
import LogoutButton from "../components/LogoutButton"
const DefaultLayout = ({ children }) => {
  return (
    <CContainer fluid>
      <CRow className="mt-3">
        <CCol style={{ textAlign: "right" }}>
          <LogoutButton />
        </CCol>
      </CRow>
      <CRow className="justify-content-md-center">
        <CCol xl={12}>{children}</CCol>
      </CRow>
    </CContainer>
  )
}

export default DefaultLayout
