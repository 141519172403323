import React, { useEffect, useState } from "react"
import {
  CProgressStacked,
  CProgress,
  CProgressBar,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
  CCard,
  CCardHeader,
  CCardBody,
  CCardTitle,
  CButton,
} from "@coreui/react"
import { CBadge } from "@coreui/react-pro"
import ControlButton from "./ControlButton"
import { formatBytes } from "../Util"
const Timer = ({
  progress,
  startTime,
  uploadTasks,
  uploadStatus,
  setUploadControl,
}) => {
  const [totalBytes, setTotalBytes] = useState(0)
  const [bytesTransferred, setBytesTransferred] = useState(0)
  const [elapsedTime, setElapsedTime] = useState(0)
  const [remainingTime, setRemainingTime] = useState(0)

  // totalBytes更新（初回のみ）
  useEffect(() => {
    setTotalBytes(
      uploadTasks.reduce((prev, task) => prev + task.snapshot.totalBytes, 0)
    )
  }, [totalBytes])

  // bytesTransferred更新（task更新時）
  useEffect(() => {
    setBytesTransferred(
      uploadTasks.reduce(
        (prev, task) => prev + task.snapshot.bytesTransferred,
        0
      )
    )
  }, [uploadTasks])

  useEffect(() => {
    if (totalBytes > 0) {
      const percentCompleted = Math.round((bytesTransferred * 100) / totalBytes)
      const elapsedTimeSeconds = (new Date() - startTime) / 1000
      const estimatedTotalTime = elapsedTimeSeconds / (percentCompleted / 100)
      setRemainingTime(estimatedTotalTime - elapsedTimeSeconds)
    }
  }, [uploadTasks])

  // 経過時間更新
  useEffect(() => {
    const interval = setInterval(() => {
      setElapsedTime((oldElapsedTime) => oldElapsedTime + 1)
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  const formatDuration = (totalSeconds) => {
    const days = Math.floor(totalSeconds / 86400)
    const hours = Math.floor((totalSeconds % 86400) / 3600)
    const minutes = Math.floor((totalSeconds % 3600) / 60)
    const seconds = Math.floor(totalSeconds % 60)
    let timeString = ""
    if (days > 0) {
      timeString += `${days}d`
      if (hours > 0) {
        timeString += `${hours}h`
      }
    }
    if (hours > 0 && days === 0) {
      timeString += `${hours}h`
    }
    if (minutes > 0 && hours === 0 && days === 0) {
      timeString += `${minutes}m`
    }
    if (seconds > 0 || (totalSeconds < 60 && timeString === "")) {
      if (timeString.length > 0) timeString += ""
      timeString += `${seconds}s`
    }
    return timeString
  }

  const colors = [
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "info",
    "dark",
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "info",
    "dark",
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "info",
    "dark",
  ]

  return (
    <>
      <CCard>
        <CCardHeader>Uploading</CCardHeader>
        <CCardBody>
          <CCardTitle style={{ fontWeight: "normal", fontSize: "92%" }}>
            {`${
              uploadTasks.filter((task) => task.snapshot.state === "running")
                .length
            }/${uploadTasks.length} files uploading...`}
          </CCardTitle>
          <CButton color="light" size="sm">
            Success{" "}
            <CBadge color="success">
              {
                uploadTasks.filter((task) => task.snapshot.state === "success")
                  .length
              }
            </CBadge>
          </CButton>
          <CButton color="light" size="sm">
            Canceled{" "}
            <CBadge color="danger">
              {
                uploadTasks.filter((task) => task.snapshot.state === "canceled")
                  .length
              }
            </CBadge>
          </CButton>

          <p className="mb-0">
            <small>
              <CBadge color="primary">経過時間</CBadge> :{" "}
              <span
                style={{
                  fontFamily: '"owners", sans-serif',
                  fontWeight: 700,
                  fontStyle: "normal",
                }}
              >
                {formatDuration(elapsedTime)}
              </span>
            </small>
          </p>
          <p className="mb-0">
            <small>
              <CBadge color="primary">残り時間</CBadge> :{" "}
              <span
                style={{
                  fontFamily: '"owners", sans-serif',
                  fontWeight: 700,
                  fontStyle: "normal",
                }}
              >
                {formatDuration(remainingTime)}
              </span>
            </small>
          </p>

          <CProgressStacked style={{ height: 20 }}>
            {uploadTasks.map((task, index) => {
              return (
                <CProgress
                  variant="striped"
                  height={20}
                  animated
                  key={`stack-${
                    task.snapshot.metadata.id
                      ? task.snapshot.metadata.id
                      : task.snapshot.metadata.fullPath
                  }`}
                  value={Math.round(
                    (task.snapshot.bytesTransferred * 100) / totalBytes
                  )}
                >
                  <CProgressBar
                    color={colors[index]}
                    value={
                      Math.round(
                        (task.snapshot.bytesTransferred * 1000) /
                          task.snapshot.totalBytes
                      ) / 10
                    }
                  />
                </CProgress>
              )
            })}
          </CProgressStacked>
          <div className="text-center">
            <small>
              {`${formatBytes(bytesTransferred)} / ${formatBytes(totalBytes)}(${
                Math.round((bytesTransferred * 1000) / totalBytes) / 10
              })%`}
            </small>
          </div>
          <CAccordion>
            <CAccordionItem>
              <CAccordionHeader>
                <small>詳細</small>
              </CAccordionHeader>
              <CAccordionBody>
                {uploadTasks.map((task, index) => {
                  return (
                    <div
                      key={`detail-${
                        task.snapshot.metadata.id
                          ? task.snapshot.metadata.id
                          : task.snapshot.metadata.fullPath
                      }`}
                    >
                      <p className="mb-0">
                        <small>
                          {task.snapshot.metadata.file
                            ? `${task.snapshot.metadata.file?.name}`
                            : task.snapshot.metadata.name}
                          （{formatBytes(task.snapshot.totalBytes)}）
                        </small>
                      </p>
                      <CProgress
                        variant="striped"
                        animated
                        thin
                        value={Math.round(
                          (task.snapshot.bytesTransferred * 100) /
                            task.snapshot.totalBytes
                        )}
                      >
                        <CProgressBar color="primary">
                          {`${
                            Math.round(
                              (task.snapshot.bytesTransferred * 1000) /
                                task.snapshot.totalBytes
                            ) / 10
                          }%`}
                        </CProgressBar>
                      </CProgress>
                    </div>
                  )
                })}
              </CAccordionBody>
            </CAccordionItem>
          </CAccordion>
        </CCardBody>
      </CCard>
      <ControlButton
        uploadStatus={uploadStatus}
        setUploadControl={setUploadControl}
        uploadTasks={uploadTasks}
      />
    </>
  )
}

export default Timer
