import React, { createContext, useContext, useState } from "react"

/**
 * Config
 */
import {
  firestore,
  storage,
  functions_asia_northeast1,
  functions_us_central1,
} from "../firebaseConfig"

/**
 * FireStore
 */
import {
  doc,
  getDoc,
  deleteDoc,
  query,
  where,
  getDocs,
  updateDoc,
  collection,
  arrayRemove,
  serverTimestamp,
} from "firebase/firestore"

/**
 * Cloud Strage
 */
import { ref, listAll, deleteObject } from "firebase/storage"

/**
 * Cloud Function
 */
import { httpsCallable } from "firebase/functions"

const FirebaseContext = createContext()

export const useFirebase = () => {
  return useContext(FirebaseContext)
}

/**
 *
 * @param {*} param0
 * @returns
 */
export const FirebaseProvider = ({ children }) => {
  const [docCollection, setDocCollection] = useState([])

  /**=====================================================
 * Cloud Functions
 =====================================================*/
  /**
   * ファイルのアップロード状態を更新
   * @param {*} inputValue
   * @returns
   */
  const updateDocStatus = async (docId, name, newStatus) => {
    const updateDocumentStatus = httpsCallable(
      functions_asia_northeast1,
      "updateDocumentStatus"
    )
    try {
      const result = await updateDocumentStatus({
        docId: docId,
        name: name,
        status: newStatus,
      })
      return result
    } catch (error) {
      throw error
    }
  }

  /**
   *
   * @param {*} docId
   * @returns
   */
  const sendEmailNotificationOnCall = async (docId) => {
    const sendMail = httpsCallable(
      functions_us_central1,
      "sendEmailNotificationOnCall"
    )
    try {
      const result = await sendMail({
        docId: docId,
      })
      return result
    } catch (error) {
      throw error
    }
  }

  /**=====================================================
 * Fire Store
 =====================================================*/

  /**
   *
   * @param {*} collectionName
   * @param {*} ownerID
   * @returns
   */
  const fetchCollection = async (collectionName, ownerID) => {
    console.log("fetchCollection", collectionName, ownerID)
    try {
      const colRef = collection(firestore, collectionName)
      const q = query(colRef, where("ownerId", "==", ownerID))
      const querySnapshot = await getDocs(q)
      setDocCollection(
        querySnapshot.docs.map((doc) => {
          return { id: doc.id, ...doc.data() }
        })
      )
      return { success: true, message: "Collection fetch successful." }
    } catch (error) {
      return { success: false, message: error }
    }
  }

  /**
   *
   * @param {*} docId
   * @param {*} emails
   * @returns
   */
  const removeMembers = async (docId, emails) => {
    const docRef = doc(
      firestore,
      process.env.REACT_APP_DEFAULT_COLLECTION,
      docId
    )
    try {
      await updateDoc(docRef, {
        sharedEmails: arrayRemove(...emails),
      })
      return { success: true, message: "Members removed successfully!" }
    } catch (error) {
      return { success: false, message: error.message }
    }
  }

  /**
   *
   * @param {*} docId
   * @param {*} fileIds
   * @returns
   */
  const removeFilesById = async (docId, fileIds) => {
    const docRef = doc(
      firestore,
      process.env.REACT_APP_DEFAULT_COLLECTION,
      docId
    )
    try {
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        const currentFiles = docSnap.data().files || []
        const filesToRemove = currentFiles.filter((file) =>
          fileIds.includes(file.id)
        )
        await updateDoc(docRef, {
          files: arrayRemove(...filesToRemove),
        })
        return { success: true, message: "Files removed successfully!" }
      } else {
        return { success: false, message: "Document does not exist" }
      }
    } catch (error) {
      return { success: false, message: error.message }
    }
  }

  /**
   *
   * @param {*} docId
   * @returns
   */
  const removeDocumentById = async (docId) => {
    const docRef = doc(
      firestore,
      process.env.REACT_APP_DEFAULT_COLLECTION,
      docId
    )
    try {
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        await deleteDoc(docRef)
        return { success: true, message: "Document removed successfully!" }
      } else {
        return { success: false, message: "Document does not exist" }
      }
    } catch (error) {
      return { success: false, message: error.message }
    }
  }

  /**
   *
   * @param {*} docIds
   * @returns
   */
  const deleteDocumentsByIds = async (docIds) => {
    try {
      for (const docId of docIds) {
        const docRef = doc(
          firestore,
          process.env.REACT_APP_DEFAULT_COLLECTION,
          docId.id
        )
        await deleteDoc(docRef)
      }
      return { success: true, message: "Documents deleted successfully!" }
    } catch (error) {
      return { success: false, message: error.message }
    }
  }

  /**=====================================================
 * Cloud Strage
 =====================================================*/
  /**
   *
   * @param {*} docId
   * @returns
   */
  const deleteFolders = async (docIds) => {
    const results = []
    for (const docId of docIds) {
      const folderRef = ref(storage, `files/${docId.id}/`)
      console.log("folderRef : ", folderRef)
      try {
        const { items } = await listAll(folderRef)
        console.log("Items : ", items)
        await Promise.all(items.map((itemRef) => deleteObject(itemRef)))
        results.push({
          docId: docId,
          success: true,
          message: "Folder deleted successfully",
        })
      } catch (error) {
        results.push({ docId: docId, success: false, message: error.message })
      }
    }
    return results
  }

  /**
   *
   * @param {*} fileIds
   * @returns
   */
  const deleteFile = async (docId, fileName) => {
    try {
      const fileRef = ref(storage, `files/${docId}/${fileName}`)
      await deleteObject(fileRef)
      return { success: true, message: "File deleted successfully" }
    } catch (error) {
      return { success: false, message: error.message }
    }

    // try {
    //   const { items } = await listAll(folderRef)
    //   console.log("Items : ", items)
    //   await Promise.all(items.map((itemRef) => deleteObject(itemRef)))
    //   results.push({
    //     docId: docId,
    //     success: true,
    //     message: "Folder deleted successfully",
    //   })
    // } catch (error) {
    //   results.push({ docId: docId, success: false, message: error.message })
    // }
    // }
    // return results
  }

  /**
   *
   */
  const contextValue = {
    firestore,
    storage,
    fetchCollection,
    removeMembers,
    removeFilesById,
    serverTimestamp,
    updateDocStatus,
    deleteDocumentsByIds,
    sendEmailNotificationOnCall,
    removeDocumentById,
    // Cloud Strage
    deleteFolders,
    deleteFile,
    docCollection,
  }

  return (
    <FirebaseContext.Provider value={contextValue}>
      {children}
    </FirebaseContext.Provider>
  )
}
