import React, { useState, useEffect } from "react"
import { CButton } from "@coreui/react"
import { FILE_UPLOAD_STATE, UPLOAD_CONTROL } from "./STATE_COSTNCE"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
const ControlButton = ({ uploadStatus, uploadTasks }) => {
  const IconPause = <FontAwesomeIcon icon={["fad", "pause"]} />
  const IconPlay = <FontAwesomeIcon icon={["fad", "play"]} />
  const [btnIcon, setBtnIcon] = useState(IconPause)
  const [btnStyle, setBtnStyle] = useState({
    label: UPLOAD_CONTROL.PAUSE.label,
    color: "primary",
  })

  const handleChangeUploadStatus = () => {
    uploadTasks.forEach((task) => {
      switch (task.snapshot.state) {
        case FILE_UPLOAD_STATE.RUNNING:
          task.pause()
          setBtnIcon(IconPlay)
          setBtnStyle({
            ...btnStyle,
            label: UPLOAD_CONTROL.RESUME.label,
            color: "primary",
          })
          break
        case FILE_UPLOAD_STATE.PAUSED:
          task.resume()
          setBtnIcon(IconPause)
          setBtnStyle({
            ...btnStyle,
            label: UPLOAD_CONTROL.PAUSE.label,
            color: "danger",
          })
          break
        default:
          break
      }
    })
  }
  return (
    <div className="d-grid gap-2 mt-5">
      <CButton
        onClick={handleChangeUploadStatus}
        size="lg"
        color={btnStyle.color}
      >
        {btnIcon}&nbsp;
        {btnStyle.label}
      </CButton>
    </div>
  )
}
export default ControlButton
