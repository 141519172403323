import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import LoginPage from "./Pages/LoginPage"
import AdminPage from "./Pages/AdminPage"
import SignUpPage from "./Pages/SignUpPage"
// import TransferPage from "./Pages/TransferPage"
import PrivateRoute from "./PrivateRoute"
import DefaultLayout from "./Layout/DefaultLayout"
import "bootstrap/dist/css/bootstrap.min.css"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/free-solid-svg-icons" //Solid
import { fab } from "@fortawesome/free-brands-svg-icons" //Brands
import { far } from "@fortawesome/pro-regular-svg-icons" //Regular
import { fal } from "@fortawesome/pro-light-svg-icons" //Light
import { fad } from "@fortawesome/pro-duotone-svg-icons" //Duotone
import { FirebaseProvider } from "./contexts/FirebaseContext"
import { AuthProvider } from "./contexts/AuthContext"
library.add(fas, fab, far, fal, fad)
const App = () => {
  return (
    <AuthProvider>
      <FirebaseProvider>
        <Router>
          <DefaultLayout>
            <Routes>
              <Route path="/" element={<PrivateRoute />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/signup" element={<SignUpPage />} />
              <Route path="/admin" element={<AdminPage />} />
            </Routes>
          </DefaultLayout>
        </Router>
      </FirebaseProvider>
    </AuthProvider>
  )
}
export default App
